import { useApolloClient, useMutation, useQuery } from '@graphcommerce/graphql'
import { CustomerTokenDocument, useCustomerQuery } from '@graphcommerce/magento-customer'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { ModalContext } from '../../context/context'
import { useLoading } from '../../context/loadingContext'
import { customerDocument } from '../../graphql/login/currentCustomerData.gql'
import { useMergeCompareList } from './useMergeCompareList'
import { useAssignCurrentCartId, useCurrentCartId, useMergeCustomerCart } from '@graphcommerce/magento-cart'
import { UseMergeCustomerCartDocument } from '@graphcommerce/magento-cart/hooks/UseMergeCustomerCart.gql'
import { CustomerCartDocument } from '@graphcommerce/magento-cart/hooks/CustomerCart.gql'

const useLogin = () => {
  const mergeCompareList = useMergeCompareList()

  const client = useApolloClient()

  const { refetch } = useQuery(customerDocument, { skip: true })

  const router = useRouter()

  const { setLoading } = useLoading()

  const { currentCartId } = useCurrentCartId()
  const assignCurrentCartId = useAssignCurrentCartId()
  const [merge] = useMutation(UseMergeCustomerCartDocument, { errorPolicy: 'all' })

  const {refetch:destinationCartFetch} = useCustomerQuery(CustomerCartDocument)

  
  // const { refetch: refetchCart } = useCartQuery(getCartDocument, { fetchPolicy: 'network-only' })

  async function mergeCart(){
    destinationCartFetch().then(async(res)=>{
      const destinationCartId = res.data.customerCart.id
      if (destinationCartId){
        if (currentCartId ) {
          await merge({ variables: { sourceCartId: currentCartId, destinationCartId } })
            .catch((e) => {
              console.error('Error merging carts', e)
            })
            .finally(() => {
              assignCurrentCartId(destinationCartId)
            })
        } else {
          assignCurrentCartId(destinationCartId)
        }
      }
    })
  }
  const useModal = useContext(ModalContext)
  const handleLogin = async (
    token: string,
    onComplete: null | (() => void) = null,
    redirect: boolean = true,
    stayAtPage: boolean = false,
  ) => {
    client.writeQuery({
      query: CustomerTokenDocument,
      data: {
        customerToken: {
          __typename: 'CustomerToken',
          token,
          createdAt: new Date().toUTCString(),
          valid: true,
        },
      },
      broadcast: true,
    })

    await refetch()
      .then(({ data: { customer } }) => {
        client.writeQuery({
          query: customerDocument,
          data: {
            customer,
          },
          broadcast: true,
        })
      })
      .then(async () => {
        // await refetchCart()I
        await Promise.all([mergeCart(), mergeCompareList()]).then(async()=>{
        if (stayAtPage) {
          await router.push(router.asPath)
        } else if (redirect) {
          await router.push('/')
        }
        if (useModal.close) useModal.close()
        if (onComplete) onComplete()
          setLoading(false)
          router.reload()
        })
      })
  }

  return { handleLogin }
}

export default useLogin
