import { useCustomerQuery, useCustomerSession } from '@graphcommerce/magento-customer'
import { customerDocument } from '../graphql/login/currentCustomerData.gql'

export default function useCustomerData() {
  const { query } = useCustomerSession()
  const { data, loading :queryLoading} = useCustomerQuery(customerDocument, {
    fetchPolicy: 'network-only',
  })
  const customerData = data?.customer

  const customerIsLogin = () => {
    if (customerData) return true
    return false
  }
  const loading = queryLoading || query.loading
  return { customerData, customerIsLogin, loading }
}
