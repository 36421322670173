/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const createAccountOTPDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createAccountOTP"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mobileNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAccountOTP"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mobileNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mobileNumber"}}},{"kind":"Argument","name":{"kind":"Name","value":"websiteId"},"value":{"kind":"IntValue","value":"1"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<createAccountOTPMutation, createAccountOTPMutationVariables>;
export type createAccountOTPMutationVariables = Types.Exact<{
  mobileNumber: Types.Scalars['String']['input'];
}>;


export type createAccountOTPMutation = { createAccountOTP?: { status?: boolean | null, message?: string | null } | null };
