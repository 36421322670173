import {
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  useFormContext
} from '@graphcommerce/react-hook-form'
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormHelperText,
  SxProps,
  Theme,
} from '@mui/material'
import { useEffect } from 'react'

export type CheckboxElementProps<T extends FieldValues> = Omit<CheckboxProps, 'name'> & {
  parseError?: (error: FieldError) => string
  label?: FormControlLabelProps['label']
  helperText?: string
  sx?: SxProps<Theme>
  formControl?: Omit<FormControlProps<'div'>, 'required' | 'error'>
} & Omit<ControllerProps<T>, 'render'>

export function CheckboxElement<TFieldValues extends FieldValues>({
  name,
  rules = {},
  required,
  parseError,
  label,
  control,
  helperText,
  sx,
  formControl,
  defaultValue,
  checked,
  ...rest
}: CheckboxElementProps<TFieldValues>): JSX.Element {
  if (required && !rules.required) {
    rules.required = 'This field is required'
  }

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(name, !!checked as any);
  }, [checked])
  return (
    <div className='relative'>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
          const parsedHelperText = error
            ? typeof parseError === 'function'
              ? parseError(error)
              : error.message
            : helperText
          return (
            <FormControl required={required} error={invalid} {...formControl}>
              <FormGroup row>
                <FormControlLabel
                  label={label || ''}
                  name={name}
                  control={
                    <Checkbox
                      {...rest}
                      color={rest.color || 'primary'}
                      sx={{
                        ...(Array.isArray(sx) ? sx : [sx]),
                        color: invalid ? 'error.main' : undefined,
                      }}
                      value={value}
                      checked={!!value}
                      onChange={() => {
                        onChange(!value)
                      }}
                    />
                  }
                />
              </FormGroup>
              {parsedHelperText && (
                <FormHelperText error={invalid} className='absolute top-[72%] right-[21px] !text-xs block w-[330px] text-right'>{parsedHelperText}</FormHelperText>
              )}
            </FormControl>
          )
        }}
      />
    </div>
  )
}
