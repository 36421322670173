import { useFormContext } from '@graphcommerce/ecommerce-ui'
import { useQuery } from '@graphcommerce/graphql'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Button } from '@mui/material'
import Link from 'next/link'
import { ReactElement, useState } from 'react'
import { useLoading } from '../../context/loadingContext'
import {
  customer_loginDocument,
  customer_loginQueryVariables,
} from '../../graphql/login/loginPassword.gql'
import IconLogin from '../icons/IconLogin'
import { TextFieldElement } from '../widgets/Form/TextFieldElement'
import CloseBtn from './CloseBtn'
import useLogin from './useLogin'
// eslint-disable-next-line import/no-cycle
import { FormType } from '.'
// eslint-disable-next-line import/no-default-export
export default function LoginForm({
  setActiveForm,
  stayAtPage,
  showLinks = false
}: {
  setActiveForm: (a: FormType) => void
  stayAtPage?: boolean
  showLinks?: boolean
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<ReactElement|null>(null)
  const { refetch } = useQuery(customer_loginDocument, { skip: true })
  const { setLoading } = useLoading()
  const { handleLogin } = useLogin()
  const methods = useFormContext()
  const handleSubmit = (values) => {
    const { mobileNumber, password } = values as customer_loginQueryVariables
    setLoading(true)
    refetch({
      mobileNumber,
      password,
    })
      .then(async (res) => {
        if (res?.data?.customer_login && res.data.customer_login.token) {
          await handleLogin(res.data.customer_login.token, null, true, stayAtPage)
          setSuccessMessage(<Trans id='Login was successful.' />)
          setErrorMessage(null)
        }
      })
      .catch((err) => setErrorMessage(String(err?.message)))
      .finally(() => setLoading(false))
  }
  return (
    <div className='pt-16 md:pt-5'>
      {errorMessage && (
        <div className='text-sm text-error-500 font-normal text-center my-4'>{errorMessage}</div>
      )}
      {successMessage && (
        <div className='text-sm text-secondary-300 font-normal text-center my-4'>
          {successMessage}
        </div>
      )}
      <p className='text-base font-medium'>
        <Trans id='users sign in' />
      </p>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextFieldElement
          name='mobileNumber'
          label={i18n._(/* i18n */ `Email or mobile phone`)}
          placeholder={i18n._(/* i18n */ `Email or mobile phone`)}
          required
          fullWidth
          variant='standard'
          classes={{ root: '!mt-5' }}
          className='!text-sm'
          color='primary'
        />

        <TextFieldElement
          name='password'
          type='password'
          label={i18n._(/* i18n */ `Password`)}
          placeholder={i18n._(/* i18n */ `Password`)}
          required
          dir='rtl'
          fullWidth
          classes={{ root: '!mt-5 rtl' }}
          className='!text-sm placeholder:text-lg'
          variant='standard'
          color='primary'
        />

        <div className='flex flex-col-reverse'>
          <div className='flex justify-center items-center mt-4'>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className='flex w-full md:w-auto text-sm justify-center bg-primary-500'
              sx={{fontWeight: 500,fontSize:'13px'}}
            >
              <IconLogin width={18} height={18} className='ml-1' />
              <Trans id='Log in' />
            </Button>
          </div>

          <div className='mt-6 flex w-full items-center justify-between'>
            <Link
              href='/account/forgot-password'
              className='text-sm text-default-100 hover:text-primary-500'
            >
              <Trans id='Password recovery' />
            </Link>
            <button
              type='button'
              className='bg-transparent border-none shadow-none outline-0 text-sm text-default-100 hover:text-primary-500'
              onClick={() => setActiveForm(FormType.code)}
            >
              <Trans id='Log in with a one-time password (SMS)' />
            </button>
          </div>
        </div>
        {showLinks && <span className='text-slate-800 text-sm flex justify-center mt-5'> اگر کاربر جدید سایت هستید<Link className='text-primary-500 hover:text-primary-700' href="/account/create">.از اینجا ثبت نام کنید</Link></span>}
        <CloseBtn />
      </form>
    </div>
  )
}
