import type { AddProductsToCartSnackbar } from '@graphcommerce/magento-product/components/AddProductsToCart/AddProductsToCartSnackbar'
import { AddProductsToCartSnackbar as AddProductsToCartSnackbarCustom } from '../components/Product/AddProductsToCartSnackbar';
import type { ReactPlugin } from '@graphcommerce/next-config'


export const component = 'AddProductsToCartSnackbar' // Component to extend, required
export const exported = '@graphcommerce/magento-product/components/AddProductsToCart/AddProductsToCartSnackbar' // Location where the component is exported, required

const ListPlugin: ReactPlugin<typeof AddProductsToCartSnackbar> = (props) => {
  // Prev in this case is ProductListItem, you should be able to see this if you log it.
  const { Prev, ...rest } = props
  return (
    <AddProductsToCartSnackbarCustom
      {...rest}
    />
  )
}
export const Plugin = ListPlugin // An export with the name Plugin, required