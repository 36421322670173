// icon:user-add | Entypo http://entypo.com/ | Daniel Bruce
import * as React from "react";

function IconUserAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 1000 1000"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M620 722c120 42.667 180 83.333 180 122v106H0V748c24-9.333 51.333-18 82-26 62.667-22.667 105.667-45.667 129-69s35-55 35-95c0-14.667-7.667-30.667-23-48s-25.667-42-31-74c-1.333-8-9-16.333-23-25-14-8.667-22.333-29-25-61 0-10.667 1.667-19.333 5-26 3.333-6.667 6.333-10.667 9-12l4-4c-5.333-33.333-9.333-62.667-12-88-4-36 9.333-73.333 40-112s84-58 160-58 129.333 19.333 160 58 44.667 76 42 112l-14 88c12 5.333 18 19.333 18 42-1.333 18.667-4.333 33-9 43s-9.333 15.667-14 17c-4.667 1.333-9.333 4-14 8s-7.667 10-9 18c-6.667 30.667-17.667 55-33 73-15.333 18-23 34.333-23 49 0 40 12 71.667 36 95s67.333 46.333 130 69m230-272h150v100H850v150H750V550H600V450h150V300h100v150" />
    </svg>
  );
}

export default IconUserAdd;
