// icon:cart3 | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from "react";

function IconCart3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 500 500"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M160,400c-13.248,0-24,10.752-24,24s10.752,24,24,24s24-10.752,24-24S173.248,400,160,400z M160,432c-4.411,0-8-3.589-8-8
		s3.589-8,8-8s8,3.589,8,8S164.411,432,160,432z"/>
      <path d="M384.5,400c-13.248,0-24,10.752-24,24s10.752,24,24,24s24-10.752,24-24S397.748,400,384.5,400z M384.5,432
		c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S388.911,432,384.5,432z"/>
      <path d="M448,128L123.177,95.646c-1.628-6.972-4.369-14.66-11.838-20.667C102.025,67.489,86.982,64,64,64v16.001
		c18.614,0,31.167,2.506,37.312,7.447c4.458,3.585,5.644,8.423,7.165,15.989l-0.024,0.004l42.052,233.638
		c2.413,14.422,7.194,25.209,13.291,32.986C171.043,379.312,180.533,384,192,384h240v-16H192c-4.727,0-19.136,0.123-25.749-33.755
		l-5.429-30.16L432,256L448,128z M418.59,242.348l-260.601,45.99l-31.732-176.3l303.869,30.608L418.59,242.348z"/>
    </svg>
  );
}

export default IconCart3;
