import * as React from "react";

function IconStatsChartSharp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 400"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M128 496H48V304h80zM352 496h-80V208h80zM464 496h-80V96h80zM240 496h-80V16h80z" />
    </svg>
  );
}

export default IconStatsChartSharp;
