/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const isNewCustomerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"isNewCustomer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mobile"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isNewCustomer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mobile"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mobile"}}}]}]}}]} as unknown as DocumentNode<isNewCustomerQuery, isNewCustomerQueryVariables>;
export type isNewCustomerQueryVariables = Types.Exact<{
  mobile: Types.Scalars['String']['input'];
}>;


export type isNewCustomerQuery = { isNewCustomer?: boolean | null };
