import { useFormContext } from '@graphcommerce/ecommerce-ui'
import { useMutation } from '@graphcommerce/graphql'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useLoading } from '../../context/loadingContext'
import { loginOTPDocument, loginOTPMutationVariables } from '../../graphql/login/loginOTP.gql'
import { TextFieldElement } from '../widgets/Form/TextFieldElement'
import { FormType } from '.'

export default function CodeForm({
  setActiveForm,
  setData,
}: {
  setActiveForm: (a: FormType) => void
  setData: (data: any) => void
}) {
  const [loginOtp] = useMutation(loginOTPDocument)
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const methods = useFormContext()

  const { setLoading } = useLoading()

  const handleSubmit = (values: any) => {
    const { mobileNumber } = values as loginOTPMutationVariables

    setLoading(true)

    loginOtp({
      variables: {
        mobileNumber,
      },
    })
      .then((res) => {
        const { data } = res
        if (data?.loginOTP?.status === true) {
          setData({ mobileNumber, mode: 'loginOtp' })
          setActiveForm(FormType.sendcode)
        } else {
          setErrorMessage(<Trans id='There was an error sending the one-time password.' />)
        }
      })
      .catch((err) => {
        setErrorMessage(<Trans id='There was an error sending the one-time password.' />)
      })
      .finally(() => setLoading(false))
  }
  return (
    <div className='pt-5'>
      {errorMessage && (
        <div className='text-sm text-error-500 font-normal text-center my-4'>{errorMessage}</div>
      )}
      <p className='text-base font-medium'>
        <Trans id='Login with a one-time password' />
      </p>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextFieldElement
          name='mobileNumber'
          label={i18n._('mobile number')}
          required
          fullWidth
          type='tel'
          variant='standard'
          classes={{ root: '!mt-5' }}
          className='!text-sm'
          color='primary'
        />

        <div className='flex justify-center items-center mt-4'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className='flex bg-primary-500 justify-center'
          >
            <Trans id='send message' />
          </Button>
        </div>
      </form>
    </div>
  )
}
