/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const removeProductsFromWishlistDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"removeProductsFromWishlist"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}},"defaultValue":{"kind":"IntValue","value":"0"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wishlistItemsIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeProductsFromWishlist"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"wishlistId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}}},{"kind":"Argument","name":{"kind":"Name","value":"wishlistItemsIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wishlistItemsIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user_errors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<removeProductsFromWishlistMutation, removeProductsFromWishlistMutationVariables>;
export type removeProductsFromWishlistMutationVariables = Types.Exact<{
  wishlistId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  wishlistItemsIds: Array<Types.Scalars['ID']['input']>;
}>;


export type removeProductsFromWishlistMutation = { removeProductsFromWishlist?: { user_errors: Array<{ message: string } | null> } | null };
