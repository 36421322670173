import { Control, Controller, FieldValues, useFormContext } from "@graphcommerce/ecommerce-ui";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import { useEffect } from "react";
import toEnglishDigits from "../../../lib/toEnglishDigits";


type BrithDayFieldProps = {
  name: string;
  control?: Control<FieldValues>
  required?: boolean;
  label?: string;
  defaultValue?: string;
}



const months = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند']
export default function BrithDayField({ name, control, required, label, defaultValue }: BrithDayFieldProps) {
  const currentYear = toEnglishDigits((new Date()).toLocaleDateString('fa-IR', { year: 'numeric' }).toString())!
  const { setValue, getValues, watch, formState: { errors } } = useFormContext();
  const selectedValue = watch(name)
  useEffect(() => {
    if (defaultValue) {
      const date = defaultValue.split('/');
      setValue(name, defaultValue)
      setValue(`${name}_day`, Number(date[2]))
      setValue(`${name}_month`, Number(date[1]))
      setValue(`${name}_year`, Number(date[0]))
    }
  }, [defaultValue]);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{
          ...(required ? { required: i18n._('Items with an asterisk are required.') } : null),
        }}
        render={({ field }) => (
          <div className="flex gap-1 items-center text-ordme-gray-500">
            <input type="hidden" {...field} />
            <label className="ml-1 text-sm">
              <Trans id="Date of birth" />

              {required && <span className="text-ordme-red">*</span>}</label>
            <select name={`${field.name}_day`}
              className="text-sm py-1 min-w-[56px] pr-1 border border-solid border-light-170 rounded-sm outline-0 shadow-none"
              onChange={e => setValue(name, selectedValue.slice(0, 8) + (Number(e.target.value) > 9 ? e.target.value : `0${e.target.value}`))}
            >
              {[...Array(31).keys()].map((item) => (
                <option value={item + 1} key={item}
                  selected={selectedValue ? Number(selectedValue.slice(8)) === item + 1 : false}

                >{item + 1}</option>
              ))}
            </select>

            <select name={`${field.name}_month`} defaultValue={1356}
              className="text-sm py-1 pr-1 min-w-[92px] border border-solid border-light-170 rounded-sm outline-0 shadow-none"
              onChange={e => setValue(name, selectedValue.slice(0, 5) + (Number(e.target.value) > 9 ? e.target.value : `0${e.target.value}`) + selectedValue.slice(7))}
            >
              {months.map((item, index) => (
                <option value={index + 1} key={index}
                  selected={selectedValue ? Number(selectedValue.slice(5, 7)) === index + 1 : false}
                >{item}</option>
              ))}
            </select>

            <select name={`${field.name}_year`} className="text-sm py-1 pr-1 min-w-[72px]  border border-solid border-light-170 rounded-sm outline-0 shadow-none"
              onChange={e => setValue(name, e.target.value + selectedValue.slice(4))}
            >
              {[...Array(99).keys()].map((item) => (
                <option value={Number(currentYear) - item} key={item}
                  selected={selectedValue ? Number(selectedValue.slice(0, 4)) === Number(currentYear) - item : false}
                >{Number(currentYear) - item}</option>
              ))}
            </select>
          </div>
        )}
      />

      {errors[`${name}_year`] ? <span className="text-sm text-error-500 font-light">{(errors[`${name}_year`] && errors[`${name}_year`]?.message)?.toString()}</span> : null}

      {errors[`${name}_month`] ? <span className="text-sm text-error-500 font-light">{(errors[`${name}_month`] && errors[`${name}_month`]?.message)?.toString()}</span> : null}

      {errors[`${name}_day`] ? <span className="text-sm text-error-500 font-light">{(errors[`${name}_day`] && errors[`${name}_day`]?.message)?.toString()}</span> : null}
    </div>
  )
}