export default function toEnglishDigits(num: string) {

  const id = {
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
  }
  return num ? num.toString().replace(/[^0-9.]/g, function (w) {
    return id[w] || w
  }) : null
}