import { Control, Controller, FieldValues, useFormContext } from "@graphcommerce/ecommerce-ui";
import { FormControl, FormControlLabel, FormControlLabelProps, FormLabel, Radio, RadioGroup, RadioGroupProps, RadioProps } from "@mui/material";
import { useEffect } from "react";

type RadioGroupElementProps = {
  name: string;
  control?: Control<FieldValues>
  items: { label: string; value: string }[];
  label?: string;
  center?: boolean;
  itemProps?: RadioProps,
  classes?: FormControlLabelProps['classes']
} & RadioGroupProps
export default function RadioGroupElement({ name, label, items, center, control, defaultValue, itemProps, ...props }: RadioGroupElementProps) {
  const { setValue, control: contextControl } = useFormContext()
  useEffect(() => {
    if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
      setValue(name, defaultValue);
    }
  }, [defaultValue])
  return (

    <Controller
      name={name}
      control={contextControl}
      render={({ field: { name, ...fieldProps } }) => (
        <FormControl className={`flex !flex-row !items-center !flex-nowrap ${center && '!justify-center'}`} fullWidth required>
          {label && <FormLabel id="demo-form-control-label-placement" className="!ml-6 text-ordme-red"><span className="text-ordme-gray-300 text-[14px]">{label}</span></FormLabel>}
          <RadioGroup
            {...fieldProps}
            {...props}
            name={name}
          >
            {items.map((item, index) => <FormControlLabel classes={props.classes} key={index} value={item.value} checked={item.value === fieldProps.value} control={<Radio {...itemProps} />} label={item.label} />)}
          </RadioGroup>
        </FormControl>
      )}
    />

  )
}