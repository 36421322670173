import { Trans } from "@lingui/react";
import { useContext } from "react"
import { ModalContext } from "../../context/context"

// eslint-disable-next-line import/no-default-export
export default function CloseBtn() {
  const useModal = useContext(ModalContext);
  return (

    <div className="w-full flex justify-center md:hidden">

      <button type="button" onClick={() => useModal.close && useModal.close()} className="text-sm md:hidden w-auto mx-auto mt-4 shadow-0 border-0 outline-0 font-medium text-default-900 hover:bg-light-170 py-2 px-4 transition-colors">
        <Trans id="Close" />
      </button>
    </div>
  )
}