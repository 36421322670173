import { useApolloClient } from "@graphcommerce/graphql"
import { CreateCompareListDocument, useAssignCurrentCompareListUid, useCompareSummary } from "@graphcommerce/magento-compare"
import { i18n } from "@lingui/core"

export function useMergeCompareList() {
  const client = useApolloClient()
  const assign = useAssignCurrentCompareListUid()
  const { data: cartSummery } = useCompareSummary();

  return async (): Promise<string> => {

    const { data } = await client.mutate({
      mutation: CreateCompareListDocument,
      variables: { products: [...(cartSummery?.compareList?.items?.map(i => i?.uid!) ?? [])] },
    })
    if (!data?.createCompareList)
      throw Error(i18n._(/* i18n */ 'Could not create a new compare list'))

    // We store the uid that is returned as the currentCompareList result
    assign(data.createCompareList.uid)

    return data.createCompareList.uid;
  }
}